<template>
  <div class="min-vh-100 tabs shadow-sm">
    <div v-if="loading === true">
      <div class="lds-ripple">
        <div></div>
      </div>
      <div class="row">
        <div class="col-12" style="text-align: center"> {{ this.loadingMessage }}</div>
      </div>
    </div>
    <div v-else>
      <input type="radio" id="tab1" name="tab-control" checked />
      <input type="radio" id="tab2" name="tab-control" />
      <ul class="ulbar font-weight-normal">
        <li>
          <label for="tab1" role="button" style="padding:12px;font-size:12px">
            คูปองที่ใช้ได้ ({{ this.usable.length || 0}})
          </label>
        </li>
        <li>
          <label for="tab2" role="button" style="padding:12px;font-size:12px">
            คูปองที่ใช้แล้ว
          </label>
        </li>
      </ul>
      <div class="slider"><div class="indicator"></div></div>

      <div class="content">
        <section>
          <div v-if="usable.length > 0">
            <div v-for="coupon in usable" :key="coupon.objectId">
              <router-link
                class="text-black"
                style="text-decoration:none"
                :to="`/redemption/${coupon.objectId}`"
                >
                <!-- <router-link
                class="text-black"
                style="text-decoration:none"
                :to="`/coupon?uid=${coupon.uid}&objectId=${coupon.objectId}`"
              > -->
                <CJumbotron
                  border-color="greyborder"
                  color="white"
                  class="p-3 mb-3">
                  <div class="row">
                    <div class="col-8">
                      <table style="width:100%" aria-describedby="">
                      <tr>
                        <th scope="col" style="width:12%">
                          <div class="text-success row">
                            <h5 class="text-dark col-10">
                              <img
                                src="https://cdn-icons-png.flaticon.com/512/8266/8266999.png"
                                alt=""
                                class="img-fluid pr-1"
                                style="width:20%"
                              />
                              {{ coupon.Coupon.title }}
                            </h5>
                          </div>
                          <p class="text-description font-weight-normal" style="font-size:12px"> {{ coupon.Coupon.description }}</p>
                        </th>
                      </tr>
                      <tr>
                        
                        <div class="text-left text-success">
                          <em class="fa fa-tag"></em> มูลค่าคูปอง : {{ coupon.value || "0" }} บาท
                        </div>
                        <div class="text-grey pt-1" style="font-size: 12px;">
                          <em class="fa fa-calendar-check-o"></em> หมดอายุ {{ expireAt(coupon) }} 
                        </div>
                      </tr>
                      </table>
                    </div>
                    <div class="col-4">
                      <table  border="0">
                        <tr>
                          <th>
                          <img :src="`${coupon.Coupon.imgUrl}`"
                              alt=""
                              class="square-box"
                              style="width:100%; background-position: 'center center'; border-radius: 3px;"
                            />
                          </th>
                        </tr>
                      </table>
                    </div>
                  </div>
                  
                </CJumbotron>
              </router-link>
            </div>
          </div>
          <div v-else-if="usable.length === 0" class="text-center">
            <img
              alt=""
              src="../../../../../public/img/coupon.png"
              class="img-fluid"
              width="50%"
            />
            <h4 class="text-dark">คุณไม่มีรายการคูปอง</h4>
          </div>
        </section>
        <section>
          <div v-if="unusable.length !== 0">
            <div v-for="coupon in unusable" :key="coupon.objectId">
              <CJumbotron color="light" class="p-3 mb-3">
                <table style="width:100%" aria-describedby="">
                  <tr>
                    <th scope="col" style="width:12%">
                      <div class="text-success row">
                        <h5 class="text-dark col-10">
                          <img
                            src="https://cdn-icons-png.flaticon.com/512/8848/8848897.png"
                            alt=""
                            class="img-fluid pr-1"
                            style="width:12%"
                          />
                          {{ coupon.Coupon.title }}
                        </h5>
                        <div class="col-2">
                            <div style="float:right;font-size: 12px;">
                            <strong v-if="coupon.isUsed === true">
                              <CBadge color="success" class="p-1 font-weight-normal">
                                <span class="font-weight-normal">&nbsp;คูปองถูกใช้แล้ว&nbsp;</span>
                              </CBadge>
                            </strong>
                            <strong v-if="coupon.isUsed === false">
                              <CBadge color="danger" class="p-1 font-weight-normal">
                                <span class="font-weight-normal">&nbsp;หมดอายุแล้ว&nbsp;</span>
                              </CBadge>
                            </strong>
                          </div>
                        </div>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <p class="text-description">
                      {{ coupon.Coupon.description }}
                    </p>
                    <div style="font-size: 12px;" v-if="coupon.isUsed === true" class="text-grey">
                      <em class="fa fa-calendar-check-o"></em> 
                      รับคูปอง {{ toDate(coupon.created_at) }}</div>
                    <div style="font-size: 12px;" v-if="coupon.isUsed === true && coupon.usedAt != undefined" class="text-grey">
                      <em class="fa fa-calendar-check-o"></em> 
                      ใช้คูปอง {{ toDate(coupon.usedAt) }}</div>
                    <div style="font-size: 12px;" v-if="coupon.isUsed === false" class="text-danger">
                      <em class="fa fa-calendar-check-o"></em> 
                      หมดอายุ {{ expireAt(coupon) }}</div>
                  </tr>
                </table>
              </CJumbotron>
            </div>
          </div>
          <div v-else-if="unusable.length === 0" class="text-center">
            <img
              alt=""
              src="../../../../../public/img/coupon.png"
              class="img-fluid"
              width="50%"
            />
            <h4 class="text-dark">คุณไม่มีรายการคูปอง</h4>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import crm from '@/services/crm'
import liff from '@line/liff'
import moment from 'moment'
import '@/util/loader.css'
import '@/util/tabs.css'
import lineutil from '@/util/line'

export default {
  data() {
    return {
      userId: '',
      profile: {},
      myCoupon: '',
      unusable: [],
      usable: [],
      loading: true,
      loadingMessage: 'Loading...',
    }
  },
  computed: {
    uid() {
      return this.$route.query.uid
    },
  },
  created() {
    document.title = 'คูปองของฉัน'
    this.loading = true

    if (process.env.NODE_ENV === "development") {
      this.userId = process.env.VUE_APP_LINE_USER_ID
      console.log('developement')
      console.log('process.env.VUE_APP_LINE_USER_ID', this.userId)
      this.getMyCoupon()
    } else {
        liff.init({ liffId: process.env.VUE_APP_LIFF_ID_MYCOUPON }).then(async () => {
        if (liff.isInClient()) {
          if (!liff.isLoggedIn()) {
            this.loadingMessage = 'Loging In...'
            liff.login()
          }
          const accessToken = liff.getAccessToken()

          if (accessToken) {
            this.loadingMessage = 'Loading user profile...'
            
            const profile = await lineutil.getProfile(accessToken)
            this.profile = profile.data
            this.userId = this.profile.userId

            this.getMyCoupon()
          }
        } else {
          this.loadingMessage = 'Your browser does not support.'
        }
      })
    }
  },
  methods: {
    noImgUrl(event) {
      event.target.src = process.env.VUE_APP_NOIMAGE
    },
    /** deprecated */
    expiredDate(date) {
      if (date === '') {
        return 'ไม่จำกัดระยะเวลา'
      } else {
        return moment(date).local(true).format('DD MMM YYYY / HH:mm')
      }
    },
    expireAt(redeemData) {
      const coupon = redeemData.Coupon || {}
      const expire = redeemData.expireAt
      if (coupon != null && coupon.dueDuration) {
        return moment(redeemData.created_at).add(coupon.dueDuration, 'm').format('DD MMM YYYY / HH:mm')
      } else if (expire === '') {
        return 'ไม่จำกัดระยะเวลา'
      } else {
        return moment(expire).local(true).format('DD MMM YYYY / HH:mm')
      }
    },
    toDate(date) {
      if (date === '') {
        return '-'
      } else {
        return moment(date).format('DD MMM YYYY / HH:mm')
      }
    },
    getMyCoupon() {
      const uid = this.uid
      const userId = this.userId
      crm
        .get('/api/v1.0/' + uid + '/getredemption/' + userId)
        .then((res) => {
          this.myCoupon = res.data.data.documents
          
          //this.getCouponData()
          this.unusable = res.data.data.invalid
          this.usable = res.data.data.valid
          this.loading = false
          this.loadingMessage = ''
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
